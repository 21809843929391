// IMPORTS
@import (reference) "../settings.less";

body[data-type="mainpage"] main {
    padding-top: 24px;
}

// MAIN BANNER & GREETING
.mainpage-greeting-wrapper {
    display: flex;
    justify-content: center;
    gap: 32px;
    color: white;

    &:has(>div:nth-child(2)) {
        aspect-ratio: ~"1440/432";
        justify-content: space-between;
    }
}

.main-banner {
    width: 949px;
    aspect-ratio: ~"949/432";
    position: relative;

    img {
        .img-crop();
    }

    .banner-img-wrapper {
        position: relative;
    }

    .text-wrapper {
        position: absolute;
        bottom: 24px;
        left: 24px;
        display: flex;
        flex-direction: column;
        padding: 20px 24px;
        background: rgba(21, 21, 21, 0.8);
        max-width: 80%;

        b {
            font-weight: 400;
            font-family: @sec-font-family;
            font-size: 24px;
            color: white;
        }

        span {
            color: @footer-gray;
        }

        &:after,
        &:before {
            position: absolute;
            width: 6px;
            aspect-ratio: 1;
            border-color: @main-color;
            border-style: solid;
            content: '';
        }

        &:after {
            top: 8px;
            left: 8px;
            border-width: 1px 0 0 1px;
        }

        &:before {
            bottom: 8px;
            right: 8px;
            border-width: 0 1px 1px 0;
        }
    }

    .swiper-pagination.banner-pagination {
        width: 34px;
        right: 0;

        .swiper-pagination-bullet {
            border-radius: 0;
            opacity: 1;
            width: 100%;
            height: 32px;
            margin: 8px 0 0 0;
            background-color: transparent;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 2px;
                height: 100%;
                background-color: @main-color;
            }

            &.swiper-pagination-bullet-active {

                &:after {
                    background-color: white;
                }
            }
        }
    }
}

.mainpage-greeting {
    padding: 48px;
    background: @sec-color;
    flex: 1;
    max-width: 949px;
    display: flex;
    flex-direction: column;
    max-height: auto;
    position: relative;

    &:after {
        position: absolute;
        z-index: 1;
        content: '';
        inset: 16px;
        border: 1px solid @main-color;
        pointer-events: none;
        background: transparent;
    }

    .headline {
        margin-bottom: 16px;
        font-size: 22px;
        color: white;
    }

    .greeting-body {
        overflow-y: auto;
        color: @footer-gray;
        padding-right: 2px;
    }
}

// PROMO BANNER
.promo-banners-wrapper {
    .grid();
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 32px;
    margin-top: -56px;
    pointer-events: none;

    &:first-child {
        padding-top: 24px;
    }
}

.promo-banner {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid @main-color;
    color: @header-color;

    figure {
        width: 40px;
        aspect-ratio: 1;
        flex-shrink: 0;

        img {
            .img-contain;
        }
    }

    b {
        font-family: @sec-font-family;
        font-size: 20px;
        font-weight: 400;
    }
}

// MAINPAGE PRODUCTS
.mainpage-products-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    gap: 0;

    .headline {
        margin-bottom: 0;
        font-size: 32px;
        margin-right: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: static;
        margin: 0;
    }

    .swiper-button-prev {
        margin-right: 2px;
    }
}